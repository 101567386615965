<script setup>
import { computed, ref, reactive, onMounted, watch } from 'vue'
import { useMq } from 'vue3-mq'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import { useObjectsStore, useAuthStore, useMainStore } from '@/stores'
import { filterData, filterAndSortList, saveDataToLS, getDataFromLS } from '@/utils'
import { filtersConfig } from '../../configs'
import { useRequests } from '@/composables'
import objectDataTip from '../../components/object-data-tip.vue'
import unassignedGeologistsTip from '../../components/unassigned-geologists-tip.vue'
import ListComp from './components/excavations-list.vue'

const objectsStore = useObjectsStore()
const authStore = useAuthStore()
const mainStore = useMainStore()
const mq = useMq()
const route = useRoute()
const router = useRouter()
const { getRequest } = useRequests()

onBeforeRouteLeave((_, from, next) => {
  saveToLSAndStore('lastQueryDataPageValue', from.query)
  next()
})

watch(
  () => route.query.type,
  (newValue) => {
    route.query.type = newValue
    const filterTypes = filtersLocal.filters.find((f) => f.id === 'type')
    filterTypes.filterValues.forEach((filter) => {
      if (newValue === 'all') {
        filter.value = true
        return
      }

      if (newValue === filter.id) {
        filter.value = true
      } else {
        filter.value = false
      }
    })
    saveToLSAndStore('lastQueryDataPageValue', route.query)
  }
)

const getAndSetObjectGeologistsToFilter = async () => {
  const objectId = route.params.id
  try {
    const data = await getRequest(`objects/${objectId}/users/`)
    const mappedGeologists = data.map((geologist) => {
      return {
        id: geologist.id,
        short_name: geologist.short_name,
        username: geologist.username,
        is_active: geologist.is_active
      }
    })

    const geologistsMap = getDataFromLS('geologists') || {}

    geologistsMap[objectId] = mappedGeologists

    saveDataToLS('geologists', geologistsMap)

    setGeologistFilter(geologistsMap[objectId])
  } catch (e) {
    console.log(e)
  }
}

const setGeologistFilter = (geologists = []) => {
  const objectGeologist = geologists.map((item) => ({
    id: item.id,
    title: item.short_name ? item.short_name : item.username,
    value: true
  }))

  const noGeologist = {
    id: null,
    title: 'Без геолога',
    value: true
  }

  const geologistFilter = filtersLocal.filters.find((f) => f.id === 'geologist')

  geologistFilter.filterValues = [...objectGeologist, noGeologist]
}

const object = computed(() => {
  return objectsStore.activeObject || null
})

const filterText = ref('')
const activeComp = ref('list')
let filtersLocal = reactive(filtersConfig)

const excavationsList = computed(() => {
  return objectsStore.excavationsList || []
})

const reconsList = computed(() => {
  return objectsStore.reconsList || []
})

const generalList = computed(() => {
  return [...excavationsList.value, ...reconsList.value]
})

const filteredExcavations = computed(() => {
  const filterTextValue = filterText.value?.trim()?.toLowerCase()
  const { filters, sort } = filtersLocal

  let currentList
  if (route.query.type === 'recon') {
    currentList = reconsList.value
  } else if (route.query.type === 'excavations') {
    currentList = excavationsList.value
  } else {
    currentList = generalList.value
  }

  const filteredList = filterData(currentList, filters, true)
  const sortedList = filterAndSortList(filteredList, filterTextValue, sort, ['title', 'site'])

  return sortedList
})

const hasRights = computed(() => {
  const geologists = objectsStore?.activeObject?.geologists
  const userId = authStore?.user?.id

  return geologists?.includes(userId) || !geologists?.length
})

const toggleModal = (id) => {
  switch (id) {
    case 'excavation':
      {
        const temp = objectsStore.isShowExcavationEditorModal
        objectsStore.setField('isShowExcavationEditorModal', !temp)
      }
      break
    case 'recon': {
      const temp = objectsStore.isShowReconEditorModal
      objectsStore.setField('isShowReconEditorModal', !temp)
    }
  }
}

const setQueryParams = (field, id, value) => {
  if (field === 'geologist') return

  let activeStatuses
  if (field === 'status') {
    activeStatuses = getActiveStatusesIDs()
    router.push({
      name: route.name,
      query: { ...route.query, status: activeStatuses.join(',') }
    })

    saveToLSAndStore('lastQueryDataPageValue', {
      ...route.query,
      status: activeStatuses.join(',')
    })
    return
  }

  if (field === 'type') {
    const filterTypes = filtersLocal.filters.find((f) => f.id === 'type')
    const isAll = filterTypes.filterValues.every((f) => f.value)

    if (isAll) {
      id = 'all'
    } else {
      const isAlreadyOffOneType = filterTypes.filterValues.some((f) => !f.value)

      if (isAlreadyOffOneType && !value) {
        const typesMap = {
          recon: 'excavations',
          excavations: 'recon'
        }

        filterTypes.filterValues.forEach((f) => {
          if (f.id === typesMap[id]) {
            f.value = true
          }
        })
      }

      const activeType = filterTypes.filterValues.find((f) => f.value)

      id = activeType.id
    }
  }

  router.push({ name: route.name, query: { ...route.query, [field]: id } })
  saveToLSAndStore('lastQueryDataPageValue', {
    ...route.query
  })
}

const saveToLSAndStore = (field, value) => {
  saveDataToLS(field, value)
  objectsStore.setField(field, value)
}

const getActiveStatusesIDs = () => {
  const statuses = filtersLocal.filters.find((f) => f.id === 'status')
  return statuses.filterValues.filter((f) => f.value).map((f) => f.id)
}

const initFiltersConfig = () => {
  const { type, status, sortorder, sortby } = route.query

  if (type) {
    changeFilterValue('type', type, true)
  } else {
    filtersLocal = filtersConfig
  }

  if (sortorder) {
    changeSortValue('order', sortorder)
  }

  if (status) {
    const statusesArr = status.split(',')
    const IDsToNum = statusesArr.map((item) => +item)
    changeFilterStatuses(IDsToNum)
  }

  if (sortby) {
    changeSortValue('value', sortby)
  }

  saveToLSAndStore('lastQueryDataPageValue', route.query)
}

const changeFilterStatuses = (activeStatusesIDs) => {
  const filterType = filtersLocal.filters.find((f) => f.id === 'status')
  filterType.filterValues.find((f) => {
    if (activeStatusesIDs.includes(f.id)) {
      f.value = true
    } else {
      f.value = false
    }
  })
}

const changeFilterValue = (filterID, typeValue, value) => {
  const filterType = filtersLocal.filters.find((f) => f.id === filterID)

  filterType.filterValues.find((f) => {
    if (typeValue === 'all') {
      f.value = true
      return
    }
    if (f.id === typeValue) {
      f.value = value
    } else {
      f.value = !value
    }
  })
}

const changeSortValue = (field, value) => {
  filtersLocal.sort[field] = value
}

const filteredFilters = computed(() => {
  const isDesktopScreen = mq.current === 'lg'

  if (!filtersLocal) return

  return {
    ...filtersLocal,
    filters: filtersLocal.filters.filter((filter) => {
      return !isDesktopScreen || !filter.showOnlyOnMobile
    })
  }
})

onMounted(() => {
  initFiltersConfig()
  if (!mainStore.isOnline || mainStore.noSyncMode) {
    const objectId = route.params.id
    const geologists = getDataFromLS('geologists')
    setGeologistFilter(geologists[objectId])
  } else {
    getAndSetObjectGeologistsToFilter()
  }
})

const dropdownItems = ref([
  {
    id: 'excavation',
    label: 'Создать скважину'
  },
  {
    id: 'recon',
    label: 'Создать точку наблюдения'
  }
])
</script>

<template>
  <div class="data-page">
    <object-data-tip v-if="mq.current === 'lg'" :object="object" />
    <unassigned-geologists-tip v-if="mq.current === 'lg'" />
    <s-search-block
      :has-button="authStore.isAdmin || hasRights"
      :filter="filterText"
      placeholder="Поиск по выработкам"
      tooltip-text="Создать выработку"
      :filters="filteredFilters"
      @change="(e) => (filterText = e)"
      @click-handler="toggleModal"
      @change-field="setQueryParams"
      data-page
      :hasButton="true"
      :dropdownItems="dropdownItems"
    />
    <list-comp
      v-if="activeComp === 'list'"
      :excavations="filteredExcavations"
      :filtered="!!filterText?.trim()"
    />
  </div>
</template>

<style lang="scss">
.data-page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 591px;
  max-width: 591px;
  max-height: 100%;

  @include tablets {
    min-width: 100%;
    height: 100%;
  }
}
</style>
