export const basicFields = {
  desktopFields: [
    {
      id: 1,
      fields: [
        {
          id: 1,
          title: 'date',
          label: 'Даты бурения',
          type: 'date'
        },
        {
          id: 3,
          title: 'coordinates',
          label: 'Координаты',
          type: 'coordinates'
        },
        {
          id: 2,
          title: 'bore_machine',
          label: 'Бур. установка',
          type: 'select',
          select: 'bore_machines'
        },
        {
          id: 4,
          title: 'method',
          label: 'Тип бурения',
          type: 'select',
          select: 'methods'
        },
        {
          id: 5,
          title: 'depth',
          label: 'Глубина',
          type: 'depth'
        },
        {
          id: 6,
          title: 'diam',
          label: 'Диаметры',
          type: 'diam'
        },
        {
          id: 7,
          title: 'geologist',
          label: 'Геолог',
          type: 'select',
          select: 'users'
        },
        {
          id: 8,
          title: 'casing',
          label: 'Крепление',
          type: 'select',
          select: 'casings'
        },
        {
          id: 9,
          title: 'bore_master',
          label: 'Бур. мастер',
          type: 'select',
          select: 'bore_masters'
        },
        {
          id: 10,
          title: 'backfill',
          label: 'Засыпка',
          type: 'select',
          select: 'backfills'
        }
      ]
    }
  ],
  mobileFields: [
    {
      id: 1,
      fields: [
        {
          id: 1,
          title: 'date',
          label: 'Дата бурения',
          type: 'date'
        },
        {
          id: 2,
          title: 'coordinates',
          label: 'Координаты',
          type: 'coordinates'
        },
        {
          id: 3,
          title: 'depth',
          label: 'Глубина',
          type: 'depth'
        },
        {
          id: 4,
          title: 'geologist',
          label: 'Геолог',
          type: 'select',
          select: 'users'
        }
      ]
    }
  ]
}

export const secondaryFields = {
  desktopFields: [
    {
      id: 2,
      fields: [
        {
          id: 1,
          title: 'geomorph_id',
          commentField: 'geomorph_comments',
          label: 'Геоморф-ие условия',
          type: 'select',
          select: 'geomorphies'
        },
        {
          id: 2,
          title: 'natural_f',
          label: 'Природные условия',
          type: 'string',
          wikiTipData: {
            color: 'placeholder',
            title: 'Природные условия',
            description:
              'При описании горных выработок на многолетнемерзлых грунтах проводят описание растительного и снежного покровов (при наличии) на момент проходки, дают характеристику положения выработки в рельефе, глубину сезонного промерзания (оттаивания) на момент бурения.',
            source:
              'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.6. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
          }
        },
        {
          id: 3,
          title: 'artificial_f',
          label: 'Техногенные условия',
          type: 'string',
          wikiTipData: {
            color: 'placeholder',
            title: 'Техногенные условия',
            description:
              'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: координаты или ситуационная привязка (на застроенных территориях)',
            source:
              'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
          }
        },
        {
          id: 4,
          title: 'site',
          label: 'Участок работ',
          type: 'string'
        },
        {
          id: 5,
          title: 'comments',
          label: 'Комментарий',
          type: 'string'
        },
        {
          id: 6,
          type: 'string',
          label: 'Задание-офис',
          title: 'office_tasks'
        },
        {
          id: 7,
          type: 'string',
          label: 'Примечание-офис',
          title: 'office_notes'
        }
      ]
    }
  ],
  mobileFields: [
    {
      id: 3,
      fields: [
        {
          id: 1,
          title: 'method',
          label: 'Тип бурения',
          type: 'select',
          select: 'methods'
        },
        {
          id: 2,
          title: 'bore_machine',
          label: 'Бур. установка',
          type: 'select',
          select: 'bore_machines'
        },
        {
          id: 3,
          title: 'bore_master',
          label: 'Бур. мастер',
          type: 'select',
          select: 'bore_masters'
        },
        {
          id: 4,
          title: 'diam',
          label: 'Диаметры',
          type: 'diam'
        },
        {
          id: 5,
          title: 'casing',
          label: 'Крепление',
          type: 'select',
          select: 'casings'
        },
        {
          id: 6,
          title: 'backfill',
          label: 'Засыпка',
          type: 'select',
          select: 'backfills'
        },
        {
          id: 7,
          title: 'geomorph_title',
          second: 'geomorph_comments',
          label: 'Геоморф-ие условия',
          type: 'string'
        },
        {
          id: 8,
          title: 'natural_f',
          label: 'Природные условия',
          type: 'string',
          wikiTipData: {
            color: 'placeholder',
            title: 'Природные условия',
            description:
              'При описании горных выработок на многолетнемерзлых грунтах проводят описание растительного и снежного покровов (при наличии) на момент проходки, дают характеристику положения выработки в рельефе, глубину сезонного промерзания (оттаивания) на момент бурения.',
            source:
              'ГОСТ Р 58325. Грунты. Полевое Описание, п. 7.1.6. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
          }
        },
        {
          id: 9,
          title: 'artificial_f',
          label: 'Техногенные условия',
          type: 'string',
          wikiTipData: {
            color: 'placeholder',
            title: 'Техногенные условия',
            description:
              'Полевая документация описания грунтов в обязательном порядке также содержит следующую дополнительную информацию: координаты или ситуационная привязка (на застроенных территориях)',
            source:
              'ГОСТ Р 58325. Грунты. Полевое Описание, п. 4.1.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
          }
        },
        {
          id: 10,
          title: 'site',
          label: 'Участок работ',
          type: 'string'
        },
        {
          id: 11,
          title: 'comments',
          label: 'Комментарий',
          type: 'string'
        },
        {
          id: 12,
          type: 'string',
          label: 'Задание-офис',
          title: 'office_tasks'
        },
        {
          id: 13,
          type: 'string',
          label: 'Примечание-офис',
          title: 'office_notes'
        }
      ]
    }
  ]
}
