<script setup>
import { ref, onMounted, watch, inject } from 'vue'
import { useMq } from 'vue3-mq'
import { useAuthStore, useMainStore, useSyncStore } from '@/stores'
import { toggleBodyMq, getServices, watchUserLocation } from '@/utils'
import db from '@/libs/db'

import AppLoading from '@/components/app-loading.vue'
import DesktopLayout from '@/layouts/desktop/desktop-layout.vue'
import MobileLayout from '@/layouts/mobile/mobile-layout.vue'
import UnsyncedModalNotify from './home/components/sync/components/unsynced-modal-notify.vue'

const mq = useMq()
const authStore = useAuthStore()
const mainStore = useMainStore()
const syncStore = useSyncStore()
const $notify = inject('$notify')

const loading = ref(true)
const ready = ref(false)

const getUserInfo = async () => {
  if (mainStore.isOnline) {
    await authStore.loadUserInfo()
  } else {
    const idbData = await db.profile.toArray()
    if (!idbData.length) return

    const profile = idbData[0]?.profile
    authStore.setUser(profile)
  }
}

onMounted(async () => {
  try {
    checkAppIsReady()
    loading.value = false
    mainStore.setLoading(true)

    const interval = mq.current === 'lg' ? 20000 : 1000
    watchUserLocation(interval)

    await getUserInfo()
    await getServices()
  } catch (error) {
    clearInterval(intervalID)
    console.log('Не удалось загрузить данные системы', error)
  } finally {
    ready.value = true
    mainStore.setLoading(false)
    clearInterval(intervalID)
  }
})

let intervalID = null

const checkAppIsReady = () => {
  let timeAgo = new Date().getTime() / 1000
  const maxTimeWaiting = 10
  let isShowNotify = false

  intervalID = setInterval(() => {
    const timePassed = calculateTimePassed(timeAgo)
    if (timePassed > maxTimeWaiting && !isShowNotify) {
      $notify({
        message:
          'Было замечено медленное интернет соединение. Для корректной работы приложения рекомендуется подключиться к другой сети или перевести телефон в авиарежим для включения оффлайн-режима приложения',
        type: 'warning',
        customClass: 'slow-internet',
        duration: 10000
      })

      isShowNotify = true
    }
  }, 500)
}

const calculateTimePassed = (timeAgo) => {
  const currentTime = new Date().getTime() / 1000
  return Math.round(currentTime - timeAgo)
}

watch(
  () => mainStore.isOnline,
  (value) => {
    onlineStatusHandler(value)
  },
  { immediate: false }
)

const onlineStatusHandler = (isOnline) => {
  const unSynced = [...syncStore.changesList, ...syncStore.dataChangesList] || []
  const position = mq.current === 'lg' ? 'top-right' : 'bottom-left'
  let offset = 60
  let message
  let type

  if (!isOnline) {
    message =
      'Вы находитесь в оффлайн - режиме. Результаты вашей работы будут сохранены на устройстве'
    type = 'info'
    offset = 74
  } else {
    if (unSynced.length) {
      message = 'Онлайн восстановлен. У вас имеются несинхронизированные данные'
      type = 'warning'
    } else {
      message = 'Онлайн восстановлен. Все материалы успешно синхронизированы'
      type = 'success'
    }
  }

  $notify({
    title: 'Оффлайн',
    customClass: `offline-notification ${mq.current}`,
    showClose: false,
    duration: 3000,
    position,
    message,
    type,
    offset
  })
}

watch(
  () => mq.current,
  (current, prev) => {
    toggleBodyMq(current, prev)
  },
  { immediate: true }
)
</script>

<template>
  <app-loading v-if="loading || !ready" :loading="loading" />
  <desktop-layout v-else-if="mq.current === 'lg'" />
  <mobile-layout v-else />
  <unsynced-modal-notify />
</template>

<style lang="scss">
body {
  overflow: hidden;
}

.notification-set:has(.slow-internet) {
  z-index: 10000 !important;
}
</style>
