import cloneDeep from 'lodash.clonedeep'

import { useSyncStore, useMainStore } from '@/stores'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import { syncLog } from '../log'
import { blobToBase64, saveImageOnServer } from '@/utils'

export const getExcavationsChanges = async () => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    await getExcavationLocalChanges()
    await getChangedItems('soils')
    await getAdditionalChanges('interlayers')
    await getAdditionalChanges('inclusions')
    await getChangedItems('groundwater')
    await getChangedItems('samples')
    await getChangedItems('coreboxes')
    await getChangedItems('images')

    syncStore.setField('changesLoaded', true)

    syncStore.setField('initialChangesList', cloneDeep(syncStore.changesList))

    if (syncStore.changesList?.length) {
      mainStore.setNoSyncMode(true)
    }
  } catch (e) {
    throw new Error(e)
  } finally {
    if (!syncStore.changesList?.length) {
      syncLog()
    }

    syncStore.setField('loading', false)
  }
}

const getExcavationLocalChanges = async () => {
  const syncStore = useSyncStore()

  const items = []

  try {
    const deletedData = await db.deleted.where('table').equals('excavations').toArray()
    await Promise.all(
      deletedData.map(async (e) => {
        await parseExcavations('deleted', e, items)
      })
    )
    const updatedData = await db.updated.where('table').equals('excavations').toArray()
    const filteredUpdated = updatedData.filter(
      (e) => !deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      filteredUpdated.map(async (e) => {
        await parseExcavations('updated', e, items)
      })
    )
    const deletedUpdated = updatedData.filter((e) =>
      deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      deletedUpdated.map(async (e) => {
        await db.updated.delete(e.id)
      })
    )
    const createdData = await db.created.where('table').equals('excavations').toArray()
    await Promise.all(
      createdData.map(async (e) => {
        await parseExcavations('created', e, items, 'id')
      })
    )

    syncStore.setField('changesList', items)
  } catch (e) {
    throw new Error(e)
  }
}

const parseExcavations = async (action, item, items, keyField = 'server_id') => {
  const data = await db.excavations.where(keyField).equals(item.item_id).first()
  let object_id, title

  if (action === 'deleted') {
    object_id = item?.object_id
    title = item?.title
  } else {
    object_id = data?.object_id
    title = data?.title
  }

  const objectData = object_id ? await db.objects.where('server_id').equals(object_id).first() : '-'
  const parseData = {
    id: `excavations:${action}:${item.item_id}`,
    object: objectData?.title_short || '-',
    item_id: item.item_id,
    type: data?.type || 101,
    idbId: item.id,
    updates: [],
    soils: [],
    isUpdated: true,
    data: data,
    action,
    title,
    object_id
  }
  items.push(parseData)
}

const getChangedItems = async (table) => {
  const items = []

  try {
    const deletedData = await db.deleted.where('table').equals(table).toArray()
    await Promise.all(
      deletedData.map(async (e) => {
        await parseItems(table, 'deleted', e, items)
      })
    )
    const updatedData = await db.updated.where('table').equals(table).toArray()
    const filteredUpdated = updatedData.filter(
      (e) => !deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      filteredUpdated.map(async (e) => {
        await parseItems(table, 'updated', e, items)
      })
    )
    const deletedUpdated = updatedData.filter((e) =>
      deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      deletedUpdated.map(async (e) => {
        await db.updated.delete(e.id)
      })
    )
    const createdData = await db.created.where('table').equals(table).toArray()
    await Promise.all(
      createdData.map(async (e) => {
        await parseItems(table, 'created', e, items, 'id')
      })
    )

    await addChangesToList(table, items)
  } catch (e) {
    throw new Error(e)
  }
}

const addChangesToList = async (table, items) => {
  const syncStore = useSyncStore()

  try {
    const changesList = syncStore.changesList

    const newList = []

    await Promise.all(
      items.map(async (e) => {
        const index = changesList.findIndex((l) => {
          if (e.excavation_idb_id) {
            if (l.action === 'created') return l.item_id === e.excavation_idb_id
            else return false
          } else {
            return l.item_id === Number(e.excavation_id)
          }
        })

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(e)
          }
        } else {
          let excavation

          if (e.excavation_idb_id) {
            excavation = await db.excavations.where('id').equals(e.excavation_idb_id).first()
          } else if (e.excavation_id) {
            excavation = await db.excavations
              .where('server_id')
              .equals(Number(e.excavation_id))
              .first()
          } else {
            const { action, idbId } = e
            await db[action].delete(idbId)
            return
          }

          if (!excavation) {
            await db[e.action].delete(e.idbId)
            return
          }

          const { object_id, title } = excavation

          const objectData = object_id
            ? await db.objects.where('server_id').equals(object_id).first()
            : '-'
          const parseData = {
            id: `excavations:updated:${e.excavation_id}`,
            object: objectData?.title_short || '-',
            item_id: e.excavation_id,
            type: excavation?.data?.type || 101,
            idbId: excavation.id,
            [table]: [e],
            data: excavation?.data,
            action: 'updated',
            title,
            object_id
          }
          newList.push(parseData)
        }
      })
    )
    if (newList.length) {
      newList.forEach((e) => {
        const index = changesList.findIndex((l) => l.item_id === e.item_id)

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(...e[table])
          }
        } else {
          changesList.push(e)
        }
      })
    }
  } catch (e) {
    throw new Error(e)
  }
}

const parseItems = async (table, action, item, items, keyField = 'server_id') => {
  if (item.image_type) return // Временное решен

  if (!item.item_id) {
    await db[action].delete(item.id)
  } else {
    const data = await db[table].where(keyField).equals(item.item_id).first()
    const excavation_id = action === 'deleted' ? item?.excavation_id : data?.excavation_id
    const parseData = {
      id: `${table}:${action}:${item.item_id}`,
      item_id: item.item_id,
      idbId: item.id,
      data: data?.data || data,
      excavation_idb_id: String(excavation_id).includes('idb_')
        ? Number(excavation_id.replace('idb_', ''))
        : null,
      action,
      excavation_id
    }

    if (action === 'deleted') {
      parseData.delete_url = item.delete_url
    }

    items.push(parseData)
  }
}

const getAdditionalChanges = async (table) => {
  const items = []

  try {
    const deletedData = await db.deleted.where('table').equals(table).toArray()
    await Promise.all(
      deletedData.map(async (e) => {
        await parseAdditional('deleted', e, items, 'id', table)
      })
    )
    const updatedData = await db.updated.where('table').equals(table).toArray()
    const filteredUpdated = updatedData.filter(
      (e) => !deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      filteredUpdated.map(async (e) => {
        await parseAdditional('updated', e, items, 'id', table)
      })
    )
    const deletedUpdated = updatedData.filter((e) =>
      deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      deletedUpdated.map(async (e) => {
        await db.updated.delete(e.id)
      })
    )
    const createdData = await db.created.where('table').equals(table).toArray()
    await Promise.all(
      createdData.map(async (e) => {
        await parseAdditional('created', e, items, 'id', table)
      })
    )
    await addAdditionalToChangesList(items, table)
  } catch (e) {
    throw new Error(e)
  }
}

const parseAdditional = async (action, item, items, keyField = 'id', table) => {
  try {
    const data = await db[table].where(keyField).equals(item.item_id).first()
    const soil_id = action === 'deleted' ? item?.soil_id : data?.soil_server_id
    const parseData = {
      id: `${table}:${action}:${item.item_id}`,
      item_id: item.item_id,
      idbId: item.id,
      soil_idb_id: data?.soil_id,
      action,
      data,
      soil_id
    }

    items.push(parseData)
  } catch (e) {
    throw new Error(e)
  }
}

const addAdditionalToChangesList = async (additionals, table) => {
  const syncStore = useSyncStore()

  try {
    const changesList = syncStore.changesList
    const newList = []

    await Promise.all(
      additionals.map(async (e) => {
        const index = changesList.findIndex((l) => {
          if (e.soil_idb_id) {
            if (l.action === 'created') return e.soils?.find((s) => s.item_id === e.soil_idb_id)
            else return false
          } else {
            return e.soils?.find((s) => s.item_id === e.soil_id)
          }
        })

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(e)
          }
        } else {
          const soil = await db.soils
            .where(e.soil_idb_id ? 'id' : 'server_id')
            .equals(e.soil_idb_id || e.soil_id)
            .first()

          const isIdbExcavation = String(soil?.excavation_id).includes('idb_')
          const excavationId = String(soil?.excavation_id).includes('idb_')
            ? Number(soil.excavation_id.replace('idb_', ''))
            : soil?.excavation_id

          const excavation = excavationId
            ? await db.excavations
                .where(!isIdbExcavation ? 'server_id' : 'id')
                .equals(Number(excavationId))
                .first()
            : null

          if (!excavation) {
            await db[e.action].delete(e.idbId)
            return
          }

          const { object_id, title } = excavation

          const objectData = object_id
            ? await db.objects.where('server_id').equals(object_id).first()
            : '-'
          const parseData = {
            id: `excavations:updated:${excavationId}`,
            object: objectData?.title_short || '-',
            item_id: excavationId,
            type: excavation?.data?.type || 101,
            idbId: excavation.id,
            updates: [],
            soils: [],
            [table]: [e],
            data: excavation?.data,
            action: 'updated',
            title,
            object_id
          }
          newList.push(parseData)
        }
      })
    )
    if (newList.length) {
      newList.forEach((e) => {
        const index = changesList.findIndex((l) => String(l.item_id) === String(e.item_id))

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(...e[table])
          }
        } else {
          changesList.push(e)
        }
      })
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const syncExcavItem = async (excavation) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    switch (excavation.action) {
      case 'deleted':
        await deleteFromServer(excavation)
        break
      case 'updated':
        await updateOnServer(excavation)
        break
      case 'created':
        await createOnServer(excavation)
        break
    }
    if (syncStore.changesList?.length) {
      syncStore.setField('initialChangesList', cloneDeep(syncStore.changesList))
    } else {
      mainStore.setNoSyncMode(false)
      setTimeout(() => {
        syncStore.setField('initialChangesList', [])
      }, 1000)
    }
  } catch (e) {
    throw new Error(e)
  } finally {
    syncStore.setField('loading', false)
    syncLog(excavation)
  }
}

export const syncAllExcav = async (list = []) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    const deletedExcavation = list.filter((e) => e.action === 'deleted')
    const updatedExcavation = list.filter((e) => e.action === 'updated')
    const createdExcavation = list.filter((e) => e.action === 'created')

    for (let i = 0; i < deletedExcavation.length; i++) {
      await deleteFromServer(deletedExcavation[i])
    }
    for (let i = 0; i < updatedExcavation.length; i++) {
      await updateOnServer(updatedExcavation[i])
    }
    for (let i = 0; i < createdExcavation.length; i++) {
      await createOnServer(createdExcavation[i])
    }
    mainStore.setNoSyncMode(false)
    setTimeout(() => {
      syncStore.setField('initialChangesList', [])
    }, 1000)
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    syncStore.setField('loading', false)
    syncLog()
  }
}

const deleteFromServer = async (item) => {
  const syncStore = useSyncStore()
  const { deleteRequest } = useRequests()

  try {
    const { id, idbId, item_id } = item
    await deleteRequest(`excavations/${item_id}/`)
    await db.deleted.delete(idbId)
    syncStore.removeItemFromChangesList(id)
  } catch (e) {
    throw new Error(e)
  }
}

const updateOnServer = async (item) => {
  const syncStore = useSyncStore()
  const { patchRequest } = useRequests()

  const { id, idbId, item_id, data } = item
  const eniqueIds = {}

  delete data?.url
  delete data?.date
  if (item.isUpdated) {
    await patchRequest(`excavations/${item_id}/`, data)
  }

  // SAVE SOILS
  const soils = item.soils?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const createdSoils = soils?.filter((s) => s.action === 'created')
  const deletedSoils = soils?.filter((s) => s.action === 'deleted')
  const updatedSoils = soils?.filter((s) => s.action === 'updated')

  if (createdSoils?.length) {
    await Promise.all(
      createdSoils.map(async (e) => {
        await createSoilOnServer(item.item_id, e)
      })
    )
  }
  if (deletedSoils?.length) {
    await Promise.all(
      deletedSoils.map(async (e) => {
        await deleteSoilOnServer(e)
      })
    )
  }
  if (updatedSoils?.length) {
    await Promise.all(
      updatedSoils.map(async (e) => {
        await updateSoilOnServer(e)
      })
    )
  }

  // SAVE ADDITIONAL
  const interlayers = item.interlayers?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const inclusions = item.inclusions?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const createdInterlayers = interlayers?.filter((s) => s.action === 'created')
  const deletedInterlayers = interlayers?.filter((s) => s.action === 'deleted')
  const updatedInterlayers = interlayers?.filter((s) => s.action === 'updated')
  const createdInclusions = inclusions?.filter((s) => s.action === 'created')
  const deletedInclusions = inclusions?.filter((s) => s.action === 'deleted')
  const updatedInclusions = inclusions?.filter((s) => s.action === 'updated')

  if (createdInterlayers?.length) {
    await Promise.all(
      createdInterlayers.map(async (e) => {
        await createAdditionalOnServer('interlayers', e)
      })
    )
  }

  if (createdInclusions?.length) {
    await Promise.all(
      createdInclusions.map(async (e) => {
        await createAdditionalOnServer('inclusions', e)
      })
    )
  }

  if (deletedInterlayers?.length) {
    await Promise.all(
      deletedInterlayers.map(async (e) => {
        await deleteAdditionalOnServer('interlayers', e)
      })
    )
  }

  if (deletedInclusions?.length) {
    await Promise.all(
      deletedInclusions.map(async (e) => {
        await deleteAdditionalOnServer('inclusions', e)
      })
    )
  }

  if (updatedInterlayers?.length) {
    await Promise.all(
      updatedInterlayers.map(async (e) => {
        await updateAdditionalOnServer('interlayers', e)
      })
    )
  }

  if (updatedInclusions?.length) {
    await Promise.all(
      updatedInclusions.map(async (e) => {
        await updateAdditionalOnServer('inclusions', e)
      })
    )
  }

  // SAVE WATER
  const water = item.groundwater?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const createdWater = water?.filter((s) => s.action === 'created')
  const deletedWater = water?.filter((s) => s.action === 'deleted')
  const updatedWater = water?.filter((s) => s.action === 'updated')

  if (createdWater?.length) {
    await Promise.all(
      createdWater.map(async (e) => {
        await createWaterOnServer(item?.data?.excavation_id || item.item_id, e)
      })
    )
  }
  if (deletedWater?.length) {
    await Promise.all(
      deletedWater.map(async (e) => {
        await deleteWaterOnServer(e)
      })
    )
  }
  if (updatedWater?.length) {
    await Promise.all(
      updatedWater.map(async (e) => {
        await updateWaterOnServer(e)
      })
    )
  }

  // SAVE SAMPLES
  const samples = item.samples?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const createdSamples = samples?.filter((s) => s.action === 'created')
  const deletedSamples = samples?.filter((s) => s.action === 'deleted')
  const updatedSamples = samples?.filter((s) => s.action === 'updated')

  if (createdSamples?.length) {
    await Promise.all(
      createdSamples.map(async (e) => {
        await createSampleOnServer(item?.data?.excavation_id || item.item_id, e)
      })
    )
  }
  if (deletedSamples?.length) {
    await Promise.all(
      deletedSamples.map(async (e) => {
        await deleteSampleOnServer(e)
      })
    )
  }
  if (updatedSamples?.length) {
    await Promise.all(
      updatedSamples.map(async (e) => {
        await updateSampleOnServer(e)
      })
    )
  }

  // SAVE COREBOXES
  const coreboxes = item.coreboxes?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })
  const createdCoreboxes = coreboxes?.filter((s) => s.action === 'created')
  const deletedCoreboxes = coreboxes?.filter((s) => s.action === 'deleted')

  if (createdCoreboxes?.length) {
    await Promise.all(
      createdCoreboxes.map(async (e) => {
        await createCoreboxOnServer(item?.data?.excavation_id || item.item_id, e)
      })
    )
  }
  if (deletedCoreboxes?.length) {
    await Promise.all(
      deletedCoreboxes.map(async (e) => {
        await deleteCoreboxOnServer(e)
      })
    )
  }

  // SAVE IMAGES
  const images = item.images?.filter((v) => {
    if (!eniqueIds[v.id]) {
      eniqueIds[v.id] = true
      return true
    } else {
      return false
    }
  })

  const createdImages = images?.filter((s) => s.action === 'created')
  const deletedImages = images?.filter((s) => s.action === 'deleted')

  if (createdImages?.length) {
    await Promise.all(
      createdImages.map(async (e) => {
        await createImageOnServer(e)
      })
    )
  }
  if (deletedImages?.length) {
    await Promise.all(
      deletedImages.map(async (e) => {
        await deleteImageOnServer(e)
      })
    )
  }

  await db.updated.delete(idbId)
  syncStore.removeItemFromChangesList(id)
}

const deleteSoilOnServer = async (soil) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(`soils/${soil.item_id}/`)
    await db.deleted.delete(soil.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const updateSoilOnServer = async (soil) => {
  const { patchRequest } = useRequests()

  try {
    const data = cloneDeep(soil.data)

    delete data.url
    delete data.date
    delete data.id
    delete data.idb_id
    delete data.server_id
    delete data.inclusions
    delete data.interlayers

    await patchRequest(`soils/${soil.item_id}/`, data)
    await db.updated.delete(soil.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const createOnServer = async (item) => {
  const syncStore = useSyncStore()
  const { postRequest } = useRequests()

  const { id, idbId, object_id, data, item_id } = item

  delete data.id

  let response

  try {
    response = await postRequest(`objects/${object_id}/excavations/`, data)
    if (!response) return
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'excavations'
      e.response.data.item_id = item_id
    }
    throw e
  }

  if (!response) return
  const excavation = {
    ...response,
    id: item_id,
    server_id: response?.id,
    object_id
  }

  await db.excavations.put(excavation)
  await db.created.delete(idbId)

  try {
    if (item.soils?.length && response?.id) {
      await Promise.all(
        item.soils.map(async (e) => {
          await createSoilOnServer(response?.id, e)
        })
      )
    }

    if (item.interlayers?.length && response?.id) {
      await Promise.all(
        item.interlayers.map(async (e) => {
          await createAdditionalOnServer('interlayers', e)
        })
      )
    }

    if (item.inclusions?.length && response?.id) {
      await Promise.all(
        item.inclusions.map(async (e) => {
          await createAdditionalOnServer('inclusions', e)
        })
      )
    }

    if (item.groundwater?.length && response?.id) {
      await Promise.all(
        item.groundwater.map(async (e) => {
          await createWaterOnServer(response?.id, e)
        })
      )
    }

    if (item.samples?.length && response?.id) {
      await Promise.all(
        item.samples.map(async (e) => {
          await createSampleOnServer(response?.id, e)
        })
      )
    }

    if (item.coreboxes?.length && response?.id) {
      await Promise.all(
        item.coreboxes.map(async (e) => {
          await createCoreboxOnServer(response?.id, e)
        })
      )
    }

    if (item.images?.length && response?.id) {
      await Promise.all(
        item.images.map(async (e) => {
          await createImageOnServer(e)
        })
      )
    }
  } catch (e) {
    console.log(e, 'inner e')
    throw e
  }

  syncStore.removeItemFromChangesList(id)
}

const createSoilOnServer = async (excavationId, soil) => {
  const { postRequest } = useRequests()

  try {
    const data = cloneDeep(soil.data)
    delete data.inclusions
    delete data.interlayers
    const response = await postRequest(`excavations/${excavationId}/soils/`, data)

    if (!response) return

    await db.soils.put({
      ...response,
      excavation_id: String(excavationId),
      server_id: response?.id,
      id: soil.item_id,
      uuid: data.uuid
    })
    await db.created.delete(soil.idbId)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'soils'
      e.response.data.item_id = soil.item_id
    }
    throw e
  }
}

const createAdditionalOnServer = async (table, additional) => {
  if (!additional.data) return
  const { postRequest } = useRequests()

  const { soil_id, id } = additional.data

  try {
    const data = cloneDeep(additional.data)
    data.comments = data.comments || ''
    const soil = await db.soils.where('id').equals(data.soil_id).first()
    if (!soil) return
    delete data.server_id
    delete data.soil_server_id
    delete data.soil_id
    const serverSoilId = Number(soil.server_id)

    if (serverSoilId) {
      const response = await postRequest(`soils/${serverSoilId}/${table}/`, data)

      if (!response) return

      const idbData = response
      idbData.server_id = response.id
      idbData.soil_id = soil_id
      idbData.soil_server_id = serverSoilId
      idbData.id = id

      await db[table].put(idbData)
      await db.created.delete(additional.idbId)
    }
  } catch (e) {
    throw new Error(e)
  }
}

const createWaterOnServer = async (excavationId, water) => {
  const { postRequest } = useRequests()

  try {
    const data = cloneDeep(water.data)

    delete data.id
    delete data.server_id

    const response = await postRequest(`excavations/${excavationId}/groundwater/`, data)
    if (!response) return
    const idbWater = response
    idbWater.server_id = response.id
    idbWater.excavation_id = String(excavationId)
    idbWater.id = water.item_id

    await db.groundwater.put(idbWater)
    await db.created.delete(water.idbId)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'groundwater'
      e.response.data.item_id = water.item_id
    }
    throw e
  }
}

const deleteWaterOnServer = async (water) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(`groundwater/${water.item_id}/`)
    await db.deleted.delete(water.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const updateWaterOnServer = async (water) => {
  const { patchRequest } = useRequests()

  try {
    const data = cloneDeep(water.data)

    delete data.url
    delete data.date
    delete data.id
    delete data.excavation_id
    delete data.server_id

    await patchRequest(`groundwater/${water.item_id}/`, data)
    await db.updated.delete(water.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const createSampleOnServer = async (excavationId, sample) => {
  const { postRequest } = useRequests()

  try {
    const data = cloneDeep(sample.data)

    delete data.id
    delete data.server_id

    const response = await postRequest(`excavations/${excavationId}/samples/`, data)
    if (!response) return
    const idbSample = response
    idbSample.server_id = response.id
    idbSample.excavation_id = String(excavationId)
    idbSample.id = sample.item_id

    await db.samples.put(idbSample)
    await db.created.delete(sample.idbId)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'samples'
      e.response.data.item_id = sample.item_id
    }
    throw e
  }
}

const deleteSampleOnServer = async (sample) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(`samples/${sample.item_id}/`)
    await db.deleted.delete(sample.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const updateSampleOnServer = async (sample) => {
  const { patchRequest } = useRequests()

  try {
    const data = cloneDeep(sample.data)

    delete data.url
    delete data.date
    delete data.id
    delete data.excavation_id
    delete data.server_id

    await patchRequest(`samples/${sample.item_id}/`, data)
    await db.updated.delete(sample.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const createCoreboxOnServer = async (excavationId, corebox) => {
  const { postRequest } = useRequests()

  try {
    const data = cloneDeep(corebox.data)
    data.image_b64 = await blobToBase64(data.image)
    delete data.id
    delete data.excavation_id
    delete data.image
    const response = await postRequest(`excavations/${excavationId}/coreboxes/`, data)
    if (!response) return
    await db.coreboxes.delete(corebox.item_id)
    await db.created.delete(corebox.idbId)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'coreboxes'
      e.response.data.item_id = corebox.item_id
    }
    throw e
  }
}

const deleteCoreboxOnServer = async (corebox) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(`coreboxes/${corebox.item_id}/`)
    await db.deleted.delete(corebox.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const createImageOnServer = async (model) => {
  try {
    await saveImageOnServer(model.data)
    await db.images.delete(model.item_id)
    await db.created.delete(model.idbId)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'images'
    }
    throw e
  }
}

const deleteAdditionalOnServer = async (table, additional) => {
  const { deleteRequest } = useRequests()

  try {
    if (additional.item_id) {
      await deleteRequest(`${table}/${additional.item_id}/`)
    }

    await db.deleted.delete(additional.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const updateAdditionalOnServer = async (table, additional) => {
  const { patchRequest } = useRequests()

  try {
    const data = cloneDeep(additional.data)

    delete data.url
    delete data.date
    delete data.id
    delete data.server_id
    delete data.soil_id
    delete data.soil_server_id
    data.comments = data.comments || ''

    if (additional?.data?.server_id) {
      await patchRequest(`${table}/${additional.data.server_id}/`, data)
    }

    await db.updated.delete(additional.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

const deleteImageOnServer = async (item) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(item.delete_url)
    await db.deleted.delete(item.idbId)
  } catch (e) {
    throw new Error(e)
  }
}
